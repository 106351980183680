import { observable, computed, action } from 'mobx'
import { CMSItem } from '../aggregate/CMSItem'
import { ICMSItemDTO } from '../interfaces/ICMSItemDTO'
import { CMSItemsPickerVM } from './CMSItemsPickerVM'

export class CMSItemVM {
  constructor(
    parentVM: CMSItemsPickerVM,
    item: ICMSItemDTO,
    isChecked: boolean = false,
    isLoaded: boolean = false
  ) {
    this.parentVM = parentVM
    this.item = item
    this.isChecked = isChecked
    if (isLoaded) this.loading = false
  }

  @observable public parentVM: CMSItemsPickerVM
  @observable public item: ICMSItemDTO
  @observable public loading: boolean = true
  @observable public isChecked: boolean = false
  @observable public isLoadingError: boolean = false
  @observable public showLightBox: boolean = false

  @computed
  public get manageVM() {
    return this.parentVM.manageVM
  }

  @action
  public setChecked = (val: boolean) => {
    if (val) this.manageVM.verifyNewSelection()
    this.isChecked = val
  }

  @action
  public toggleChecked = () => {
    this.setChecked(!this.isChecked)
  }

  @action
  public handleImageLoad() {
    this.loading = false
  }

  @action
  public handleImageLoadError() {
    this.loading = true
    this.isLoadingError = true
  }

  @action
  public toggleLightBox() {
    this.showLightBox = !this.showLightBox
  }

  @computed
  public get isLoaded(): boolean {
    if (!this.loading && !this.isLoadingError) return true
    return false
  }

  @computed
  public get itemId(): number {
    if (!this.item) return
    return this.item.id
  }

  @computed
  public get itemIdStr(): string {
    if (!this.item) return
    return this.item.id.toString()
  }

  @computed
  public get fileId(): number {
    if (!this.item.File) return
    return this.item.File.id
  }

  @computed
  public get title(): string {
    return this.item.Title
  }

  @computed
  public get description(): string {
    return this.item.Description
  }

  @computed
  public get createdAt(): string {
    return this.item.createdAt
  }

  @computed
  public get updatedAt(): string {
    return this.item.updatedAt
  }

  @computed
  public get isPrivate(): boolean {
    return this.item.Is_Private
  }

  @computed
  public get type(): string {
    if (Boolean(this.item.Optimized_Video)) return 'video'
    const file = this.item.File
    if (file) {
      if (this.item.File.mime.includes('video')) return 'video'
      if (this.item.File.mime.includes('image')) return 'image'
    }
    const extVideo = this.item.External_Video
    if (extVideo) {
      return 'video'
    }
    return 'raw'
  }

  @computed
  public get thumbnail(): string {
    if (this.hasOptimizedVideo) return this.item.Optimized_Video.playback_id

    const file = this.item.File
    if (file) {
      const formats = this.item.File.formats
      if (this.item.File.mime.includes('video')) {
        if (formats) {
          if (formats.large) return formats.large.url
          if (formats.medium) return formats.medium.url
          if (formats.small) return formats.small.url
          if (formats.thumbnail) return formats.thumbnail.url
          return
        }
        return file.url
      }
      if (file.mime.includes('image')) {
        if (!formats) {
          return file.url
        } else {
          if (formats.large) return formats.large.url
          if (formats.medium) return formats.medium.url
          if (formats.small) return formats.small.url
          if (formats.thumbnail) return formats.thumbnail.url
          return
        }
      }
      return file.url
    }
    const extVideo = this.item.External_Video
    if (extVideo) {
      if (extVideo.url) return extVideo.url
    }
    return
  }

  @computed
  public get url(): string {
    const file = this.item.File
    if (file) {
      const formats = this.item.File.formats
      if (this.item.File.mime.includes('video')) {
        if (formats) {
          if (formats.large) return formats.large.url
          if (formats.medium) return formats.medium.url
          if (formats.small) return formats.small.url
          if (formats.thumbnail) return formats.thumbnail.url
          return
        }
        return file.url
      }
      if (file.mime.includes('image')) {
        if (!formats) {
          return file.url
        } else {
          if (formats.large) return formats.large.url
          if (formats.medium) return formats.medium.url
          if (formats.small) return formats.small.url
          if (formats.thumbnail) return formats.thumbnail.url
          return
        }
      }
      return file.url
    }
    const extVideo = this.item.External_Video
    if (extVideo) {
      if (extVideo.url) return extVideo.url
    }
    return
  }

  @computed
  public get altText(): string {
    if (!this.item.File) return ''
    if (this.item.File.mime.includes('image')) return this.item.File.alternativeText
    return ''
  }

  @computed
  public get hasFile(): boolean {
    if (!this.item.File) return false
    return true
  }

  @computed
  public get hasExternalVideo(): boolean {
    if (!this.item.External_Video) return false
    if (!this.item.External_Video.url) return false
    return true
  }

  @computed
  public get hasOptimizedVideo(): boolean {
    if (Boolean(this.item.Optimized_Video) && Boolean(this.item.Optimized_Video.playback_id))
      return true
    return false
  }

  @computed
  public get hasTags(): boolean {
    if (!this.tags) return false
    return true
  }

  @computed
  public get tags(): any {
    if (!this.item.Item_Tags) return
    if (!this.item.Item_Tags.length) return false
    return this.item.Item_Tags
  }

  @computed
  public get extVideoUrl(): string {
    if (!this.item.External_Video) return ''
    if (!this.item.External_Video.url) return ''
    return this.item.External_Video.url
  }

  @computed
  public get fileType(): string {
    if (this.type === 'image') return 'image'
    if (this.type === 'video') return 'video'
    if (this.type === 'file') return 'raw'
    return 'raw'
  }

  @computed
  public get aggregate(): CMSItem {
    if (!this.item) return
    return CMSItem.create(this.item)
  }

  @computed
  public get viewModels() {
    return this.parentVM
  }
}
