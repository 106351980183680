import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { SurveyTemplate } from '../aggregates/SurveyTemplate'
import { RootStore } from '../../stores/RootStore'
import { SurveyTemplatesTableVM } from './SurveyTemplatesTableVM'
import { observable, computed, action } from 'mobx'
import { SurveyTemplatesService } from '../services/SurveyTemplatesService'
import moment from 'moment'

export class SurveyTemplateRowVM extends AGGridRowVM<SurveyTemplate> {
  private svc: SurveyTemplatesService

  constructor(
    rootStore: RootStore,
    surveyTemplate: SurveyTemplate,
    tableVM: SurveyTemplatesTableVM
  ) {
    super(rootStore, surveyTemplate, tableVM)
    this.tableVM = tableVM
    this.svc = new SurveyTemplatesService(rootStore)
  }

  @observable public tableVM: SurveyTemplatesTableVM = null
  @observable public deleteConfirm: boolean = false
  @observable deletePending: boolean = false
  private blankValue = '-'
  private timeZone: string = moment.tz.guess()

  @computed
  public get shouldRender(): boolean {
    return true
  }

  @computed
  public get subscribedOrganizationIds(): string[] {
    return this.aggregate.subscribedOrganizationIds
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get created(): string {
    return `${this.createdAt} ${this.createdByUserId}`
  }

  @computed
  public get createdAt(): string {
    const formattedDate = moment(this.aggregate.createdAt)
      .tz(this.timeZone)
      .format('M/D/YYYY')
    return formattedDate
  }

  @computed
  public get createdByUserId(): string {
    if (this.aggregate.fk_createdByUser) {
      return this.aggregate.fk_createdByUser.name
    }
    return this.blankValue
  }

  @computed
  public get publishedByUserId() {
    return this.aggregate.publishedByUserId
  }

  @computed
  public get owner(): string {
    if (this.aggregate.fk_owner) {
      return this.aggregate.fk_owner.name
    }
    return this.blankValue
  }

  @computed
  public get questionsCount(): number {
    return this.aggregate.questionsCount
  }

  @computed
  public get updated(): string {
    return `${this.updatedAt} ${this.updatedByUserId}`
  }

  @computed
  public get updatedAt(): string {
    if (this.aggregate.updatedAt) {
      const formattedDate = moment(this.aggregate.updatedAt)
        .tz(this.timeZone)
        .format('M/D/YYYY')
      return formattedDate
    }
    return this.blankValue
  }

  @computed
  public get updatedByUserId(): string {
    if (this.aggregate.fk_updatedByUser) {
      return this.aggregate.fk_updatedByUser.name
    }
    return this.blankValue
  }

  @action
  public navigateToEditPage() {
    this.rootStore.appStore.router.push(`/surveyTemplates/edit/${this.objectId}`)
  }

  @action
  public openDeleteDialog() {
    this.deleteConfirm = true
    this.tableVM.selectedRow = this
  }

  @action
  public closeDeleteDialog() {
    this.deleteConfirm = false
  }

  @action
  public async copySurveyTemplate() {
    this.tableVM.selectedRow = this
    await this.svc.copySurveyTemplate(this.objectId)
    this.tableVM.openSnackbar('Template copied successfully')
  }

  @action
  public async deleteSurveyTemplate() {
    this.deletePending = true
    this.closeDeleteDialog()
    await this.svc.deleteSurveyTemplate(this.objectId)
    this.deletePending = false
    this.deleteConfirm = false
    this.tableVM.openSnackbar('Template deleted successfully')
  }
}
