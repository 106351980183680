import moment from 'moment'
import { RootStore } from '../../../stores/RootStore'
import { SurveyTemplate } from '../../aggregates/SurveyTemplate'
import { action, computed, observable } from 'mobx'

export class SurveyTemplateSelectRowVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, template: SurveyTemplate) {
    this.rootStore = rootStore
    this.template = template
  }

  @observable public template: SurveyTemplate = null

  private timeZone: string = moment.tz.guess()
  private blankValue = '-'

  @computed
  public get shouldRender(): boolean {
    return true
  }

  @computed
  public get objectId(): string {
    return this.template.objectId
  }

  @computed
  public get name(): string {
    if (!this.template.name) return 'Untitled'
    return this.template.name
  }

  @computed
  public get createdAt(): string {
    if (!this.template.createdAt) return this.blankValue
    const formattedDate = moment(this.template.createdAt)
      .tz(this.timeZone)
      .format('M/D/YYYY, h:mm A')
    return formattedDate
  }

  @computed
  public get publishedByUserId() {
    if (!this.template.publishedByUserId) return this.blankValue
    return this.template.publishedByUserId
  }

  @computed
  public get owner() {
    if (!this.template.owner) return 'Unknown'
    return this.template.owner
  }

  @computed
  public get questionsCount(): number {
    return this.template.questionsCount
  }

  @action
  public selectSystemTemplate() {
    this.rootStore.appStore.router.push(`/surveys/fromsystemtemplate/${this.objectId}`)
  }

  @action
  public navigateToEditPage() {
    let orgId = this.rootStore.appStore.currentOrgId
    this.rootStore.appStore.router.push(`/surveyTemplates/edit/${this.objectId}/${orgId}`)
  }

}
